import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap';
import ScrollToTop from 'react-scroll-up';
import { AllHtmlEntities } from 'html-entities';
import Layout from '../components/Layout';
import styles from './post.module.css';

export const BlogPostTemplate = ({ content, title, date, helmet }) => {
  // replace local wp paths with full paths, and remove wp-added "row" class since it conflicts with bootstrap row class and screws up page styling on mobile
  const contentNoRows =
    content &&
    content
      // .replace(new RegExp('/wp-content/', 'g'), 'https://wp.veym.net/wp-content/')
      .replace(new RegExp('class="row"', 'g'), '');

  // count number of occurences of table of contents list items, and remove table of contents div if 0
  const contentsItems = (
    (contentNoRows && contentNoRows.match(/tableofcontentsgenerateditem/g)) ||
    []
  ).length;

  const contentwToC =
    contentsItems === 0
      ? contentNoRows &&
        contentNoRows.replace(
          new RegExp('<div id="toc-np-container">[^<]*(.*?)</div>', 'gm'),
          ''
        )
      : contentNoRows;

  return (
    <section className="section">
      {helmet || ''}
      <div className={styles.wordpressContent}>
        <div className={styles.headerContainerRow}>
          <Col sm={{ size: 10, offset: 1 }}>
            <div className={styles.headerContainerColumn}>
              <h2 className="display-6">
                {new AllHtmlEntities().decode(title)}
              </h2>
            </div>
          </Col>
        </div>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/news">News</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Post</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <Container>
          <Row>
            <Col xs={12}>
              <p style={{ marginTop: '1rem' }}>{date}</p>

              <div dangerouslySetInnerHTML={{ __html: contentwToC }} />
            </Col>
            <div style={{ marginTop: `4rem` }} />
          </Row>
          <ScrollToTop showUnder={160} duration={1050}>
            <Button size="sm" className={styles.button}>
              <i className="fa fa-arrow-circle-up" />
            </Button>
          </ScrollToTop>
        </Container>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
  // helmet: PropTypes.instanceOf(Helmet),
};

const BlogPost = ({ data }) => {
  const { wpPost: post } = data;

  const helmetComponent = (
    <Helmet
      title={`VEYM | ${post.title}`}
      meta={[
        {
          name: 'description',
          content: post.excerpt,
        },
      ]}
    />
  );

  return (
    <Layout>
      <BlogPostTemplate
        content={post.content}
        helmet={helmetComponent}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  fragment PostFields on WpPost {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          slug
          name
        }
      }
      tags {
        nodes {
          slug
          name
        }
      }
    }
  }
`;
